import { Box, Checkbox, Grid, Group, Paper, Select, Stack, TextInput, Title, Text } from '@mantine/core';
import React, { useCallback, useMemo } from 'react';
import { UnitedStates } from '@/utils/usa';
import { HL7ValueSet, PreferredLanguage, System, extractValues } from 'const-utils';
import { useOutreach } from '../Context';
import ContactEditForm from '@/components/ContactEditForm';
import { Coding } from '@medplum/fhirtypes';
import { Patient as GraphqlPatient, Maybe, SearchPatientByAddressQueryVariables } from 'medplum-gql';
import { capitalize } from 'lodash';
import { useRecommendedLinks } from '@/hooks/useRecommendedLinks';
import { formatAddress, formatAgeAndDateOfBirth, pluralize } from 'imagine-dsl/utils/strings';
import { getName, is21OrOlder } from 'imagine-dsl/utils/patient';
import { CodingInput } from '@medplum/react';
import { PatientEligibilityBadge } from '@/components/shared/patient/PatientEligibilityBadge';
import { Over21Badge } from '@/components/Over21Badge';

type ReviewContactInfoComponent = JSX.Element;

const PreferredLanguageCodes = extractValues(PreferredLanguage);

export default function ReviewContactInfo(): ReviewContactInfoComponent {
  const outreach = useOutreach();
  const {
    contactReviewForm: form,
    patient,
    queuePatientToLink,
    updatedQueuedLinkRelationship,
    dequeuePatientToLink,
    queuedPatientsToLink,
  } = outreach;

  const getAddressSearch = useCallback((): Maybe<SearchPatientByAddressQueryVariables> => {
    if (!form.values.address?.line1) {
      return null;
    }

    return {
      address: formatAddress({
        ...form.values.address,
        line: [form.values.address.line1, form.values.address.line2].filter(Boolean) as string[],
      }),
      addressCity: form.values.address.city,
      addressPostalCode: form.values.address.zip,
      addressState: form.values.address.state,
    };
  }, [form.values.address]);
  const { data: recommendedLinks = [] } = useRecommendedLinks(patient, getAddressSearch);

  return (
    <Paper shadow="xs" p="xl" radius="lg">
      <Title mb="xl" order={3}>
        Review contact info
      </Title>
      <form id="review-contact-info-form" style={{ width: '100%' }}>
        <Title order={4}>Patient's address</Title>
        <br />
        <Group grow align="start">
          <TextInput
            withAsterisk
            label="Address line 1"
            placeholder="123 E Main Street"
            name="addressLine1"
            {...form.getInputProps('address.line1')}
          />
          <TextInput
            name="addressLine2"
            label="Address line 2"
            placeholder="Unit 1"
            {...form.getInputProps('address.line2')}
          />
        </Group>
        <br />
        <Grid grow>
          <Grid.Col span={6}>
            <TextInput
              name="city"
              withAsterisk
              label="City"
              placeholder="Chicago"
              {...form.getInputProps('address.city')}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Select
              data-cy="state-select"
              withAsterisk
              label="State"
              placeholder="Select state"
              data={UnitedStates.map((state) => ({ label: state.name, value: state.abbreviation }))}
              {...form.getInputProps('address.state')}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              name="zipCode"
              withAsterisk
              label="Zip code"
              placeholder="60601"
              {...form.getInputProps('address.zip')}
            />
          </Grid.Col>
        </Grid>
        <br />
        <Title order={4}>Patient's language preference</Title>
        <br />
        <Group>
          <Select
            data-cy="language-select"
            label="Language"
            placeholder="Select language"
            data={PreferredLanguageCodes.map((d) => d.display) as string[]}
            {...form.getInputProps('languagePreferences.language')}
          />
          <Checkbox
            label="Requires interpreter"
            pt="lg"
            {...form.getInputProps('languagePreferences.requiresInterpreter', { type: 'checkbox' })}
          />
        </Group>
        {recommendedLinks?.length > 0 && (
          <Box mt="lg">
            <Title order={4}>Link patient(s)</Title>
            <Text>
              There {pluralize(recommendedLinks, 'are', 'is')}{' '}
              <Text span fw="bold">
                {recommendedLinks.length}
              </Text>{' '}
              {pluralize(recommendedLinks, 'patients', 'patient')} who may be associated with{' '}
              <Text span fw="bold">
                {getName(patient)}
              </Text>
            </Text>
            <Stack mt="sm">
              {recommendedLinks.map((link) => {
                return (
                  <RecommendedLinkItem
                    key={link.id}
                    link={link}
                    queuePatientToLink={queuePatientToLink}
                    updatedQueuedLinkRelationship={updatedQueuedLinkRelationship}
                    dequeuePatientToLink={dequeuePatientToLink}
                    queuedPatientsToLink={queuedPatientsToLink}
                  />
                );
              })}
            </Stack>
          </Box>
        )}
        <br />
        <Title order={4}>Contact</Title>
        <br />
        <Paper bg="brand-gray.0" p="xl" radius="lg">
          <ContactEditForm formWithContact={form} />
        </Paper>
        <br />
      </form>
    </Paper>
  );
}

type RecommendedLinkItemProps = {
  link: GraphqlPatient;
  queuePatientToLink: (patient: GraphqlPatient, relationship: Coding) => void;
  updatedQueuedLinkRelationship: (patient: GraphqlPatient, relationship?: Coding) => void;
  dequeuePatientToLink: (patient: GraphqlPatient) => void;
  queuedPatientsToLink: { patient: GraphqlPatient; relationship?: Coding }[];
};

const RecommendedLinkItem = ({
  link,
  queuePatientToLink,
  updatedQueuedLinkRelationship,
  dequeuePatientToLink,
  queuedPatientsToLink,
}: RecommendedLinkItemProps) => {
  const linkAddress = useMemo(() => {
    return (
      link?.address?.find(
        (address) =>
          address.extension?.find((ext) => ext.url === System.AddressType.toString() && ext.valueCode === 'home'),
      ) ?? link?.address?.[0]
    );
  }, [link]);
  const queuedLink = queuedPatientsToLink.find((p) => p.patient.id === link.id);

  return (
    <Group>
      <Checkbox
        flex="2"
        key={link.id}
        label={
          <Box>
            <Group>
              <Text>
                {getName(link)} {formatAgeAndDateOfBirth(link?.birthDate)}
                {is21OrOlder(link?.birthDate) && <Over21Badge />}
                {link.gender ? ` ${capitalize(link.gender)}` : ''}
              </Text>
              <PatientEligibilityBadge patientTags={link.meta?.tag || []} />
            </Group>
            {linkAddress && <Text c="dimmed">{formatAddress(linkAddress)}</Text>}
          </Box>
        }
        checked={!!queuedLink}
        onChange={(e) => {
          if (e.target.checked) {
            queuePatientToLink(link, {
              code: 'caregiver',
              display: 'Caregiver',
              system: HL7ValueSet.RelatedPersonRelationshipType,
            });
          } else {
            dequeuePatientToLink(link);
          }
        }}
      />
      {!!queuedLink && (
        <Box flex="1">
          <CodingInput
            name="relationship"
            path="relationship"
            required
            label="Caregiver relationship"
            binding={HL7ValueSet.RelatedPersonRelationshipType}
            defaultValue={queuedLink.relationship}
            onChange={(relationship) => updatedQueuedLinkRelationship(link, relationship)}
            withHelpText={false}
          />
        </Box>
      )}
    </Group>
  );
};

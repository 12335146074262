import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Group, Modal, Select, Text } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { CareTeamType } from 'const-utils';
import { CareTeam } from '@medplum/fhirtypes';
import { notifications } from '@mantine/notifications';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';
import { handlePodChange } from 'imagine-dsl/services/careTeamService';
import { GetOutreachPanelQuery } from 'imagine-gql/client';
import { IconInfoCircle } from '@tabler/icons-react';

interface EngagementPodAssignmentModalProps {
  isModalOpen: boolean;
  setModalOpen: (isModalOpen: boolean) => void;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>> | Promise<ApolloQueryResult<GetOutreachPanelQuery>>;
  patientIds?: string[];
}

const EngagementPodAssignmentModal = ({
  isModalOpen,
  setModalOpen,
  refetch,
  patientIds,
}: EngagementPodAssignmentModalProps) => {
  const medplum = useMedplum();

  const [engagementPods, setEngagementPods] = useState<CareTeam[]>([]);
  const [updatedPodId, setUpdatedPodId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [engagementCareTeamIds, setEngagementCareTeamIds] = useState<string[]>([]);
  const numberOfPatients = patientIds?.length ?? 0;

  const fetchCareTeamIds = useCallback(async () => {
    try {
      const engagementCareTeamIdsPromises = patientIds?.map(async (id) => {
        const engagementCareTeam = await medplum.searchOne('CareTeam', {
          _tag: CareTeamType.Engagement,
          patient: id,
        });
        setEngagementCareTeamIds((prev) => {
          if (engagementCareTeam?.id && !prev.includes(engagementCareTeam.id)) {
            return [...prev, engagementCareTeam.id];
          }
          return prev;
        });
      });
      await Promise.all(engagementCareTeamIdsPromises ?? []);
    } catch (error) {
      notifications.show({
        title: 'Error fetching care teams:',
        message: error as string,
        color: 'status-error',
      });
    }
  }, [patientIds, medplum]);

  const fetchEngagementPods = useCallback(async () => {
    try {
      const pods = await medplum.search('CareTeam', {
        _tag: CareTeamType.EngagementPod,
      });
      setEngagementPods(pods.entry?.map((entry) => entry.resource) as CareTeam[]);
    } catch (error) {
      notifications.show({
        title: 'Error fetching engagement pods:',
        message: error as string,
        color: 'status-error',
      });
    }
  }, [medplum]);

  useEffect(() => {
    fetchCareTeamIds().catch((error) => {
      notifications.show({
        title: 'Error fetching care teams:',
        message: error as string,
        color: 'status-error',
      });
    });
  }, [fetchCareTeamIds]);

  useEffect(() => {
    fetchEngagementPods().catch((error) => {
      notifications.show({
        title: 'Error fetching engagement pods:',
        message: error as string,
        color: 'status-error',
      });
    });
  }, [fetchEngagementPods]);

  const onSubmitHandler = async () => {
    setLoading(true);
    const careTeamIdsToUpdate = engagementCareTeamIds;

    for (const careTeamId of careTeamIdsToUpdate) {
      await handlePodChange(medplum, careTeamId, updatedPodId, engagementPods);
    }

    notifications.show({
      title: 'Success',
      message: 'Engagement pod updated successfully.',
      color: 'status-success',
    });
    await refetch();
    setModalOpen(false);
  };

  const ModalTitle = () => (
    <>
      <Text size="lg" fw={700} c="imagine-green" mb="sm">
        Update Engagement Pod Assignment
      </Text>
      {numberOfPatients > 1 && (
        <Alert variant="light" color="blue" icon={<IconInfoCircle />}>
          <Group gap="xs">
            <b>Please note:</b> The engagement pod will be updated for <b>{patientIds?.length} patients</b>
          </Group>
        </Alert>
      )}
    </>
  );

  return (
    <Modal
      title={<ModalTitle />}
      size="lg"
      opened={isModalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      radius="lg"
      centered
    >
      <Text fz="xs" mb="sm">
        <Select
          data={engagementPods.map((pod) => ({
            value: pod.id ?? '',
            label: pod.name ?? '',
          }))}
          value={updatedPodId}
          onChange={(value) => setUpdatedPodId(value ?? '')}
        />
      </Text>
      <Group mt="md" justify="flex-end">
        <Button variant="outline" onClick={() => setModalOpen(false)} radius="md">
          Cancel
        </Button>
        <Button type="submit" radius="md" disabled={!updatedPodId} loading={loading} onClick={onSubmitHandler}>
          Save changes
        </Button>
      </Group>
    </Modal>
  );
};

export default EngagementPodAssignmentModal;

import { getErrorMessage } from '@/errors';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { Button, Group, Modal, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMedplum } from '@medplum/react';
import {
  GetIncompleteChatTasksDocument,
  GetIncompleteChatTasksQuery,
  GetLastChatTaskByPatientIdDocument,
} from 'medplum-gql';
import { reopenTask, resolveTask } from 'imagine-dsl/services/taskService';
import React, { useState } from 'react';

interface ResolveAlertModalProps {
  opened: boolean;
  onClose: () => void;
  taskId: string;
  refreshTasks: () => Promise<ApolloQueryResult<GetIncompleteChatTasksQuery>> | Promise<void>;
  encounterId: string;
  taskStatus: string;
}

export function ResolveAlertModal({
  opened,
  onClose,
  taskId,
  refreshTasks,
  encounterId,
  taskStatus,
}: ResolveAlertModalProps): JSX.Element {
  const medplum = useMedplum();
  const apolloClient = useApolloClient();
  const [isResolving, setIsResolving] = useState(false);

  const taskCompleted = taskStatus === 'completed';

  const onStatusUpdate = (): void => {
    if (isResolving) {
      return;
    }

    setIsResolving(true);

    if (taskCompleted) {
      reopenTask(medplum, taskId, encounterId)
        .then(async () => {
          onClose();
          notifications.show({
            message: 'Alert reopened',
            color: 'status-success',
          });

          //refetches all tasks to update the command center table
          await apolloClient.refetchQueries({ include: [GetIncompleteChatTasksDocument] });

          return refreshTasks().catch((err: unknown) => {
            notifications.show({
              color: 'status-error',
              title: 'Error refreshing tasks',
              message: getErrorMessage(err),
            });
          });
        })
        .catch((err: unknown) => {
          notifications.show({
            color: 'status-error',
            title: 'Error reopening alert',
            message: getErrorMessage(err),
          });
        })
        .finally(() => {
          setIsResolving(false);
        });
    } else {
      resolveTask(medplum, taskId, encounterId)
        .then(async () => {
          onClose();
          notifications.show({
            message: 'Alert resolved',
            color: 'status-success',
          });

          //updates the resolved task and refetches all tasks to update the command center table
          await apolloClient.refetchQueries({
            include: [GetIncompleteChatTasksDocument, GetLastChatTaskByPatientIdDocument],
          });

          return refreshTasks().catch((err: unknown) => {
            notifications.show({
              color: 'status-error',
              title: 'Error refreshing tasks',
              message: getErrorMessage(err),
            });
          });
        })
        .catch((err: unknown) => {
          notifications.show({
            color: 'status-error',
            title: 'Error resolving alert',
            message: getErrorMessage(err),
          });
        })
        .finally(() => {
          setIsResolving(false);
        });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={<Text c="imagine-green">{taskCompleted ? 'Reopen' : 'Resolve'} alert</Text>}
    >
      <Text>Are you sure you want to {taskCompleted ? 'reopen' : 'resolve'} this alert?</Text>
      <Group pt={16} style={{ justifyContent: 'flex-end' }}>
        <Button loading={isResolving} onClick={() => onStatusUpdate()}>
          {taskCompleted ? 'Reopen' : 'Resolve'} alert
        </Button>
        <Button variant="outline" onClick={() => onClose()}>
          Cancel
        </Button>
      </Group>
    </Modal>
  );
}
